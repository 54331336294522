// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sense-module__wrapper___KvFi1{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-line-pack:stretch;align-content:stretch;height:100%;min-height:50vh;max-height:calc(100vh - 150px - 102px)}.Sense-module__wrapper___KvFi1.Sense-module__NotOwner___Vs0PX{max-height:calc(100vh - 150px - 102px - 140px)}.Sense-module__wrapper___KvFi1>div>div{height:100%}.Sense-module__wrapper___KvFi1>:first-child>div{overflow-x:hidden}.Sense-module__wrapper___KvFi1>:nth-child(2){-webkit-box-flex:1;-ms-flex:1;flex:1;position:relative;left:-2px}", "",{"version":3,"sources":["webpack://./src/features/sense/ui/Sense.module.scss"],"names":[],"mappings":"AAEA,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,0BAAA,CAAA,qBAAA,CACA,WAAA,CACA,eAAA,CACA,sCAAA,CAEA,8DACE,8CAAA,CAMF,uCACE,WAAA,CAIA,gDAEE,iBAAA,CAIJ,6CACE,kBAAA,CAAA,UAAA,CAAA,MAAA,CACA,iBAAA,CACA,SAAA","sourcesContent":["@import '../../../layouts/variables.module';\n\n.wrapper {\n  display: flex;\n  align-content: stretch;\n  height: 100%;\n  min-height: 50vh;\n  max-height: calc(100vh - $reservedTopHeight - $actionBarHeight);\n\n  &.NotOwner {\n    max-height: calc(\n      100vh - $reservedTopHeight - $actionBarHeight - $robotHeaderHeightNotOwner\n    );\n  }\n\n  // Display\n  > div > div {\n    height: 100%;\n  }\n\n  > :first-child {\n    > div {\n      // don't understand this scroll\n      overflow-x: hidden;\n    }\n  }\n\n  > :nth-child(2) {\n    flex: 1;\n    position: relative;\n    left: -2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Sense-module__wrapper___KvFi1",
	"NotOwner": "Sense-module__NotOwner___Vs0PX"
};
export default ___CSS_LOADER_EXPORT___;
